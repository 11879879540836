<template>
  <v-dialog
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="computedWidth"
    v-model="show"
  >
    <v-card :loading="loading">
      <v-card-title class="text-button">
        <span v-if="value && value.received">
          Editar produto
        </span>
        <span v-else>
          Selecionar produto
        </span>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-switch
                  flat
                  persistent-hint
                  label="Criar este produto"
                  hint="Desabilite caso queira selecionar um produto já cadastrado"
                  v-model="create"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  outlined
                  hide-details="auto"
                  label="Produto do Fornecedor"
                  :autofocus="create"
                  :disabled="!create"
                  :rules="[notEmptyRule]"
                  v-model="description"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <number-field
                  dense
                  outlined
                  disabled
                  hide-details
                  label="Quantidade"
                  :suffix="supplierProduct.unit"
                  :precision="{
                    min: 0,
                    max: 4,
                  }"
                  :value="supplierProduct.quantity"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <number-field
                  dense
                  outlined
                  hide-details
                  label="Preço de Venda"
                  :disabled="!create"
                  :prefix="salePrice ? 'R$' : undefined"
                  :rules="salePriceRules"
                  v-model="salePrice"
                />
              </v-col>
            </v-row>

            <v-row v-if="!create">
              <v-col class="py-0">
                <v-checkbox
                  flat
                  persistent-hint
                  color="green"
                  hint="Atualiza o preço de venda mantendo a mesma margem de lucro"
                  label="Atualizar preço de venda"
                  v-model="updateSalePrice"
                />
              </v-col>

              <v-col cols="12">
                <v-data-table
                  dense
                  disable-filtering
                  disable-pagination
                  disable-sort
                  fixed-header
                  hide-default-footer
                  :headers="headers"
                  :items="products"
                >
                  <template v-slot:item.remove="{ index }">
                    <v-btn icon small color="grey" @click="removeLine(index)">
                      <v-icon small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>

                  <template v-slot:item.product="{ item }">
                    <product-field
                      required
                      solo
                      flat
                      hide-details
                      showCostPrice
                      showPrice
                      showStock
                      label=""
                      placeholder="Nome do produto"
                      :autofocus="!create"
                      :rules="[notEmptyRule]"
                      v-model="item.product"
                      @change="productUpdated(item, $event)"
                    />
                  </template>

                  <template v-slot:item.quantity="{ item }">
                    <number-field
                      required
                      solo
                      flat
                      hide-details
                      placeholder="0,0000"
                      class="v-text-field--text-right"
                      :suffix="item.unit"
                      :precision="{
                        min: 0,
                        max: 4,
                      }"
                      :rules="[minDecimalRule(0.0001, item.quantity)]"
                      v-model="item.quantity"
                    />
                  </template>

                  <template v-slot:body.append="{ isMobile }">
                    <template v-if="isMobile">
                      <v-col>
                        <v-btn small text color="success" @click="newLine">
                          <v-icon left>mdi-plus-thick</v-icon>
                          Adicionar mais um produto
                        </v-btn>
                      </v-col>
                    </template>

                    <tr v-else>
                      <td></td>
                      <td :colspan="2">
                        <v-btn small text color="success" @click="newLine">
                          <v-icon left>mdi-plus-thick</v-icon>
                          Adicionar mais um produto
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-6 justify-center">
        <v-btn depressed @click="$emit('close')">
          <span>Cancelar</span>
        </v-btn>

        <v-btn v-if="value && value.received" depressed @click="remove">
          <v-icon left>mdi-restore</v-icon>
          <span>Restaurar</span>
        </v-btn>

        <v-btn large color="success" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Decimal from "decimal.js";
import UpdateModel from "@/mixins/UpdateModel";
import Rules from "@/mixins/Rules";
import ProductField from "@/components/ProductField";
import NumberField from "@/components/NumberField";

export default {
  name: "NotaFiscalReceiveProduct",
  mixins: [UpdateModel, Rules],
  components: {
    ProductField,
    NumberField,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      loading: false,
      create: false,
      updateSalePrice: false,
      description: "",
      salePrice: undefined,
      products: [],
    };
  },
  computed: {
    show: {
      get() {
        return !!this.value;
      },
      set(v) {
        if (!v) {
          this.$emit("close");
        }
      },
    },
    computedWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "sm":
          return "90%";
        case "md":
          return "80%";
        case "lg":
          return "60%";
        case "xl":
          return "40%";
      }
      return "auto";
    },
    supplierProduct() {
      return {
        id: this.value?.product.id,
        description: this.value?.product.description,
        unit: this.value?.unit || "UN",
        quantity: this.value?.quantity,
        salePrice: this.value?.unitPrice,
      };
    },
    salePriceRules() {
      if (this.create) {
        let unitPrice = new Decimal(this.value?.unitPrice || 0.01).toNumber();
        return [this.minDecimalRule(unitPrice, this.salePrice)];
      }
      return [];
    },
    received() {
      return this.value?.received || {};
    },
    headers() {
      return [
        {
          value: "remove",
          align: "center",
          width: 16,
        },
        {
          text: "Produto",
          value: "product",
        },
        {
          text: "Quant.",
          value: "quantity",
          align: "center",
          width: 118,
        },
      ];
    },
  },
  watch: {
    create(v) {
      if (!v) {
        this.description = this.supplierProduct.description;
        this.salePrice = undefined;
      } else {
        this.salePrice =
          this.received.sale_price || this.supplierProduct.salePrice;
      }
    },
    value(v) {
      if (!v) {
        this.loading = false;
        return;
      }

      if (!v.received) {
        this.create = true;
        this.updateSalePrice = true;
        this.description = this.supplierProduct.description;
        this.salePrice = this.supplierProduct.salePrice;
        this.products = [];
      } else {
        this.create = v.received.create;
        this.description =
          this.received.description || this.supplierProduct.description;
        if (this.create) {
          this.salePrice =
            this.received.sale_price || this.supplierProduct.salePrice;
        } else {
          this.salePrice = undefined;
        }
        this.updateSalePrice = v.received.update_sale_price;
        this.products = (v.received.products || []).map((line) => ({
          product: {
            id: line.product_id,
            description: line.description,
          },
          unit: line.unit,
          quantity: line.quantity,
        }));
      }
    },
  },
  methods: {
    newLine() {
      this.products.push({
        unit: this.supplierProduct.unit,
        quantity: this.products.length == 0 ? this.supplierProduct.quantity : 0,
      });
    },
    removeLine(index) {
      this.products.splice(index, 1);
    },
    productUpdated(item) {
      if (item.product) {
        item.unit = item.product.unit;
      } else {
        item.unit = this.supplierProduct.unit;
      }
    },
    async remove() {
      this.updateValueObject({
        received: null,
      });
      this.$nextTick().then(() => {
        this.$emit("close");
      });
    },
    async save() {
      this.loading = true;
      if (!this.$refs.form.validate()) {
        this.loading = false;
        return;
      }

      let received = {
        create: this.create,
      };
      if (this.create) {
        if (
          this.description.localeCompare(this.supplierProduct.description) !== 0
        ) {
          received.description = this.description;
        }
        received.sale_price = this.salePrice;
      } else {
        if (this.products.length === 0) {
          received = undefined;
        } else {
          received.update_sale_price = this.updateSalePrice;
          received.products = this.products.map((line) => ({
            product_id: line.product.id,
            description: line.product.description,
            unit: line.product.unit,
            quantity: line.quantity,
          }));
        }
      }

      this.updateValueObject({ received });
      this.$nextTick().then(() => {
        this.$emit("close");
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.v-data-table::v-deep
  tbody > tr > td, thead > tr > th
    padding-right: 0 !important
    padding-left: 10px !important
</style>
