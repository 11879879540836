<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col cols="12" class="py-0">
        <nota-fiscal-title
          title="Nota Fiscal Recebida"
          :authorized-at="authorizedAt"
          :back-to="{ name: 'purchase-list' }"
          :number="value.number"
          :serie="value.serie"
        />
      </v-col>

      <v-expand-transition>
        <banner
          :nf-type="value.model"
          :sefaz-id="value.sefaz_id"
          :value="banner"
          :xml-url="value.xml_url"
        />
      </v-expand-transition>

      <v-col cols="12">
        <v-card outlined :loading="receiving">
          <v-form ref="form">
            <operation
              readonly
              :value="value.operation"
              :point-of-sale="value.point_of_sale"
            />

            <recipient readonly label="Fornecedor" :value="supplier" />

            <receive-products
              ref="products"
              class="pt-0"
              :status="value.status"
              :tax-regime="supplierTaxRegime"
              v-model="products"
            />

            <additional-data readonly class="pt-0" :value="notes" />

            <total
              class="pt-0"
              :total-lines="totals.lines"
              :total-discount="totals.discount"
              :total-shipping="totals.shipping"
              :total-insurance="totals.insurance"
              :tax-base-icms="totals.icmsTaxBase"
              :total-icms="totals.icms"
              :tax-base-icms-st="totals.icmsTaxBaseST"
              :total-icms-st="totals.icmsST"
              :total-pis="totals.pis"
              :total-cofins="totals.cofins"
              :total-ipi="totals.ipi"
              :total="totals.total"
            />
          </v-form>

          <v-card-actions
            class="py-12 px-sm-8 justify-center justify-sm-space-between"
          >
            <v-btn outlined class="d-none d-sm-flex" @click="$router.back()">
              Voltar
            </v-btn>

            <div v-if="value.status != 'received'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    tile
                    color="error"
                    class="mx-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteNfe"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>

                <span>Remover Nota Fiscal</span>
              </v-tooltip>

              <v-btn
                large
                color="success"
                :loading="receiving"
                @click="receive"
              >
                <v-icon left>mdi-application-import</v-icon>
                Receber produtos
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pt-3">
          <span>Carregando Nota Fiscal</span>
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Decimal from "decimal.js";
import { mapState } from "vuex";
import NotaFiscalTitle from "@/components/NotaFiscal/Title";
import Banner from "@/components/NotaFiscal/Banner";
import Operation from "@/components/NotaFiscal/Operation";
import Recipient from "@/components/NotaFiscal/Recipient";
import ReceiveProducts from "@/components/NotaFiscal/ReceiveProducts";
import AdditionalData from "@/components/NotaFiscal/AdditionalData";
import Total from "@/components/NotaFiscal/Total";

export default {
  name: "PurchaseView",
  metaInfo() {
    return {
      title: "Nova Nota Fiscal Recebida",
    };
  },
  components: {
    NotaFiscalTitle,
    Banner,
    Operation,
    Recipient,
    ReceiveProducts,
    AdditionalData,
    Total,
  },
  data: () => ({
    value: {},
    loading: false,
    receiving: false,
    products: [],
  }),
  computed: {
    supplierTaxRegime() {
      return this.supplier?.taxRegime || "simple";
    },
    authorizedAt() {
      // purchases have authorized_at == issue_date
      if (this.value.issue_date) {
        return new Date(this.value.issue_date);
      }
      return null;
    },
    banner() {
      const { status } = this.value;
      if (!status) return "";
      if (status === "received") return "received";
      return "receive";
    },
    supplier() {
      const { supplier } = this.value;
      if (!supplier) return;

      return {
        type: supplier.tax_id.length === 14 ? "company" : "person",
        taxNumber: supplier.tax_id,
        name: supplier.name,
        fiscalName: supplier.fiscal_name,
        phone: supplier.phone,
        email: supplier.email,
        stateTaxNumber: supplier.state_tax_number,
        taxRegime: supplier.tax_regime,
        address: {
          zipcode: supplier.address.zipcode,
          street: supplier.address.street,
          number: supplier.address.number,
          city: {
            id: supplier.address.city.id,
            name: supplier.address.city.name,
            state: supplier.address.city.state_code,
          },
          neighbourhood: supplier.address.neighbourhood,
        },
      };
    },
    notes() {
      return {
        additional: this.value.notes || "",
        fiscal: this.value.notes_authorities || "",
      };
    },
    totals() {
      return {
        lines: new Decimal(this.value.totals?.total_lines || 0),
        discount: new Decimal(this.value.totals?.total_discount || 0),
        shipping: new Decimal(this.value.totals?.total_shipping || 0),
        insurance: new Decimal(this.value.totals?.total_insurance || 0),
        total: new Decimal(this.value.totals?.total || 0),
        icmsTaxBase: new Decimal(this.value.totals?.icms_tax_base || 0),
        icms: new Decimal(this.value.totals?.total_icms || 0),
        icmsTaxBaseST: new Decimal(this.value.totals?.total_icms_st || 0),
        icmsST: new Decimal(this.value.totals?.total_icms_st || 0),
        ipi: new Decimal(this.value.totals?.total_ipi || 0),
        pis: new Decimal(this.value.totals?.total_pis || 0),
        cofins: new Decimal(this.value.totals?.total_cofins || 0),
        tax: new Decimal(0),
      };
    },
    ...mapState("auth", ["company"]),
  },
  watch: {
    company() {
      this.$nextTick().then(() => {
        // when a new company is selected, go back to list
        this.$router.push({ name: "purchase-list" });
      });
    },
  },
  methods: {
    validate() {
      const { form, products } = this.$refs;
      let errs;
      if (form.validate()) {
        const valid = (errs = products.validate()) === true;
        if (valid) {
          return true;
        }
      } else {
        this.notifyError("Alguns campos obrigatórios não foram preenchidos 😕");
        return false;
      }
      this.notifyError(
        "Encontramos alguns problemas ao tentar salvar a NF 😕<ul><li>" +
          errs.join("</li><li>") +
          "</li></ul>"
      );
      return false;
    },
    async receive() {
      this.receiving = true;
      if (!this.validate()) {
        this.receiving = false;
        return;
      }
      let lines = [];
      this.products.map(({ received }) => {
        lines.push({
          received,
        });
      });
      try {
        await this.$http.post(`/v1/purchases/${this.value.id}/receive`, {
          lines,
        });
        this.$router.push("/notas-fiscais/recebidas");
        this.$nextTick().then(() => {
          this.notifySuccess("Nota Fiscal recebida com sucesso 🎉");
        });
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao receber a Nota Fiscal 😢");
      } finally {
        this.receiving = false;
      }
    },
    async deleteNfe() {
      const msg = `Tem certeza que deseja deletar a Nota Fiscal ${this.value.serie} / ${this.value.number} de ${this.supplier.name}?`;
      if (!confirm(msg)) {
        return;
      }
      try {
        await this.$http.delete(`/v1/purchases/${this.value.id}`);
        this.$router.push({ path: "/notas-fiscais/recebidas" });
      } catch (err) {
        this.$sentry.captureException(err);
        this.notifyError("Ocorreu um erro ao deletar a Nota Fiscal 😢");
      }
    },
    async loadPurchase(id) {
      this.loading = true;

      try {
        this.value = await this.$store.dispatch("purchase/fetchById", id);
        this.products = (this.value.lines || []).map((line) => ({
          product: {
            id: line.product_id,
            description: line.description,
            unit: line.unit,
            ncm: line.ncm,
            cest: line.cest,
            origin: line.origin,
            cost_price: line.unit_price,
            sale_price: line.unit_price,
          },
          barcode: line.barcode,
          goodsOrigin: line.origin,
          unit: line.unit,
          quantity: line.quantity,
          unitPrice: line.unit_price,
          totalPrice: line.total_price,
          ncm: line.ncm,
          cest: line.cest,
          cfop: line.cfop,
          notes: line.notes,
          icmsTaxCode: line.icms.tax_code,
          icmsTaxBaseMode: line.icms.tax_base_mode,
          icmsTaxBase: line.icms.tax_base,
          icmsTaxRate: line.icms.tax_rate,
          icmsTotal: line.icms.total,
          pisTaxCode: line.pis.tax_code,
          pisTaxBase: line.pis.tax_base,
          pisTaxRate: line.pis.tax_rate,
          pisTotal: line.pis.total,
          cofinsTaxCode: line.cofins.tax_code,
          cofinsTaxBase: line.cofins.tax_base,
          cofinsTaxRate: line.cofins.tax_rate,
          cofinsTotal: line.cofins.total,
          ipiTaxCode: line.ipi?.tax_code || null,
          received: line.received || null,
        }));
      } catch (err) {
        this.$router.push({ name: "purchase-list" });
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 300);
      }
    },
  },
  mounted() {
    const { id } = this.$route.params;
    if (!id) return;
    setTimeout(() => {
      this.loadPurchase(id);
    }, 300);
  },
};
</script>
