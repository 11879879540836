var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":_vm.computedWidth},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"text-button"},[(_vm.value && _vm.value.received)?_c('span',[_vm._v(" Editar produto ")]):_c('span',[_vm._v(" Selecionar produto ")])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"flat":"","persistent-hint":"","label":"Criar este produto","hint":"Desabilite caso queira selecionar um produto já cadastrado"},model:{value:(_vm.create),callback:function ($$v) {_vm.create=$$v},expression:"create"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"auto","label":"Produto do Fornecedor","autofocus":_vm.create,"disabled":!_vm.create,"rules":[_vm.notEmptyRule]},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('number-field',{attrs:{"dense":"","outlined":"","disabled":"","hide-details":"","label":"Quantidade","suffix":_vm.supplierProduct.unit,"precision":{
                  min: 0,
                  max: 4,
                },"value":_vm.supplierProduct.quantity}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('number-field',{attrs:{"dense":"","outlined":"","hide-details":"","label":"Preço de Venda","disabled":!_vm.create,"prefix":_vm.salePrice ? 'R$' : undefined,"rules":_vm.salePriceRules},model:{value:(_vm.salePrice),callback:function ($$v) {_vm.salePrice=$$v},expression:"salePrice"}})],1)],1),(!_vm.create)?_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-checkbox',{attrs:{"flat":"","persistent-hint":"","color":"green","hint":"Atualiza o preço de venda mantendo a mesma margem de lucro","label":"Atualizar preço de venda"},model:{value:(_vm.updateSalePrice),callback:function ($$v) {_vm.updateSalePrice=$$v},expression:"updateSalePrice"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","disable-filtering":"","disable-pagination":"","disable-sort":"","fixed-header":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.remove",fn:function(ref){
                var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){return _vm.removeLine(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1)]}},{key:"item.product",fn:function(ref){
                var item = ref.item;
return [_c('product-field',{attrs:{"required":"","solo":"","flat":"","hide-details":"","showCostPrice":"","showPrice":"","showStock":"","label":"","placeholder":"Nome do produto","autofocus":!_vm.create,"rules":[_vm.notEmptyRule]},on:{"change":function($event){return _vm.productUpdated(item, $event)}},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}})]}},{key:"item.quantity",fn:function(ref){
                var item = ref.item;
return [_c('number-field',{staticClass:"v-text-field--text-right",attrs:{"required":"","solo":"","flat":"","hide-details":"","placeholder":"0,0000","suffix":item.unit,"precision":{
                      min: 0,
                      max: 4,
                    },"rules":[_vm.minDecimalRule(0.0001, item.quantity)]},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"body.append",fn:function(ref){
                    var isMobile = ref.isMobile;
return [(isMobile)?[_c('v-col',[_c('v-btn',{attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.newLine}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar mais um produto ")],1)],1)]:_c('tr',[_c('td'),_c('td',{attrs:{"colspan":2}},[_c('v-btn',{attrs:{"small":"","text":"","color":"success"},on:{"click":_vm.newLine}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Adicionar mais um produto ")],1)],1)])]}}],null,false,1070084043)})],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"pb-6 justify-center"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v("Cancelar")])]),(_vm.value && _vm.value.received)?_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-restore")]),_c('span',[_vm._v("Restaurar")])],1):_vm._e(),_c('v-btn',{attrs:{"large":"","color":"success"},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save-outline")]),_vm._v(" Salvar ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }