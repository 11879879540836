<template>
  <div>
    <v-card-title class="section-title">
      Dados Fiscais dos Produtos
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-data-table
            disable-filtering
            disable-pagination
            disable-sort
            fixed-header
            hide-default-footer
            :dense="$vuetify.breakpoint.mdAndDown"
            :headers="headers"
            :items="items"
          >
            <template v-slot:item.icon="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="item.received"
                    icon
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectProduct = item"
                  >
                    <v-icon small>mdi-check-all</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    icon
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectProduct = item"
                  >
                    <v-icon small>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>

                <span v-if="item.received">Editar produto</span>
                <span v-else>Selecionar produto</span>
              </v-tooltip>
            </template>

            <template v-slot:item.product="{ item }">
              <div
                :class="{
                  'secondary--text text--lighten-2':
                    (item.received && !item.received.create) ||
                    item.newDescription,
                }"
              >
                {{ item.product.description }}
              </div>

              <div v-if="item.newDescription">
                {{ item.newDescription }}
              </div>

              <template v-if="item.received && !item.received.create">
                <ul>
                  <li
                    v-for="product in item.received.products"
                    :key="product.product_id"
                  >
                    {{ product.description }}
                  </li>
                </ul>
              </template>
            </template>

            <template v-slot:item.quantity="{ item }">
              <div
                :class="{
                  'secondary--text text--lighten-2':
                    item.received && !item.received.create,
                }"
              >
                <number :value="item.quantity" />
                {{ item.unit }}
              </div>

              <div v-if="item.received && !item.received.create">
                <div
                  v-for="product in item.received.products"
                  :key="product.product_id"
                >
                  <number :value="product.quantity" />
                  {{ product.unit }}
                </div>
              </div>
            </template>

            <template v-slot:item.unitPrice="{ item }">
              <div class="text-right">
                <currency :value="item.unitPrice || 0" />
              </div>
            </template>

            <template v-slot:item.totalPrice="{ item }">
              <div class="text-right">
                <currency :value="item.totalPrice || 0" />
              </div>
            </template>

            <template v-slot:item.ncm="{ item }">
              <ncm-field text :value="item.ncm" />
            </template>

            <template v-slot:item.cfop="{ item }">
              <cfop-field text :value="item.cfop" />
            </template>

            <template v-slot:item.aliquot="{ item }">
              <v-btn depressed small block @click="editItem = item">
                <v-icon left color="success">mdi-check-circle</v-icon>
                <span>Visualizar</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <product-edit
      readonly
      nfeOperation="purchase"
      :value="editItem"
      :fiscal-regime="taxRegime"
      @close="editItem = null"
    />
    <receive-product
      :value="selectProduct"
      @input="selectProductUpdated"
      @close="selectProduct = null"
    />
  </div>
</template>

<script>
import UpdateModel from "@/mixins/UpdateModel";
import Number from "@/components/Number";
import Currency from "@/components/Currency";
import CfopField from "@/components/CfopField";
import NcmField from "@/components/NcmField";
import ProductEdit from "./ProductEdit";
import ReceiveProduct from "./ReceiveProduct";

export default {
  name: "NotaFiscalReceiveProducts",
  mixins: [UpdateModel],
  components: {
    Number,
    Currency,
    CfopField,
    NcmField,
    ProductEdit,
    ReceiveProduct,
  },
  props: {
    value: Array,
    taxRegime: {
      required: true,
      type: String,
    },
    status: String,
  },
  data: () => ({
    editItem: null,
    selectProduct: null,
  }),
  computed: {
    local() {
      return this.value || [];
    },
    headers() {
      let headers = [];
      if (this.isPending) {
        headers.push({
          value: "icon",
          align: "center",
          width: 32,
        });
      }
      return headers.concat([
        {
          text: "Produto",
          value: "product",
        },
        {
          text: "Quant.",
          value: "quantity",
          align: "right",
          width: 118,
        },
        {
          text: "Custo Unit.",
          value: "unitPrice",
          align: "right",
          width: 94,
        },
        {
          text: "Custo Total",
          value: "totalPrice",
          align: "right",
          width: 94,
        },
        {
          text: "NCM",
          value: "ncm",
          align: "center",
          width: 80,
        },
        {
          text: "CFOP",
          value: "cfop",
          align: "center",
          width: 80,
        },
        {
          text: "Info. Fiscais",
          value: "aliquot",
          width: 140,
        },
      ]);
    },
    isPending() {
      return this.status === "pending";
    },
    items() {
      return this.local.map((item, i) => {
        const { received } = item;
        if (received?.create) {
          var receivedDescription;
          if (this.isPending) {
            receivedDescription = received.description;
          } else if (received.products?.length > 0) {
            receivedDescription = received.products[0].description;
          }

          var newDescription =
            receivedDescription?.localeCompare(item.product.description) === 0
              ? null
              : receivedDescription;
        }

        return {
          index: i,
          ...item,
          newDescription,
        };
      });
    },
  },
  methods: {
    selectProductUpdated(item) {
      this.updateValueIndex(item.index, item);
    },
    validate() {
      let errs = [];
      for (const index in this.value) {
        const line = this.value[index];
        if (!line.received) {
          errs.push(
            `Nenhum produto selecionado para:<br/><strong>${line.product.description}</strong>`
          );
          continue;
        }

        if (line.received?.product?.id && !line.received?.quantity) {
          const descOrIndex =
            line.received.product?.description || parseInt(index) + 1;

          errs.push(
            `Não informado quantidade para o produto <strong>${descOrIndex}</strong>`
          );
        }
      }
      return errs.length > 0 ? errs : true;
    },
  },
};
</script>

<style lang="sass" scoped>
.v-text-field--text-right::v-deep
  input
    text-align: right

.v-data-table::v-deep
  tbody > tr > td, thead > tr > th
    padding-right: 0 !important
    padding-left: 10px !important

    &:last-child
      padding-right: 10px !important
</style>
