var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"section-title"},[_vm._v(" Dados Fiscais dos Produtos ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","fixed-header":"","hide-default-footer":"","dense":_vm.$vuetify.breakpoint.mdAndDown,"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.received)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){_vm.selectProduct = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-all")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"orange"},on:{"click":function($event){_vm.selectProduct = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[(item.received)?_c('span',[_vm._v("Editar produto")]):_c('span',[_vm._v("Selecionar produto")])])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
                'secondary--text text--lighten-2':
                  (item.received && !item.received.create) ||
                  item.newDescription,
              }},[_vm._v(" "+_vm._s(item.product.description)+" ")]),(item.newDescription)?_c('div',[_vm._v(" "+_vm._s(item.newDescription)+" ")]):_vm._e(),(item.received && !item.received.create)?[_c('ul',_vm._l((item.received.products),function(product){return _c('li',{key:product.product_id},[_vm._v(" "+_vm._s(product.description)+" ")])}),0)]:_vm._e()]}},{key:"item.quantity",fn:function(ref){
              var item = ref.item;
return [_c('div',{class:{
                'secondary--text text--lighten-2':
                  item.received && !item.received.create,
              }},[_c('number',{attrs:{"value":item.quantity}}),_vm._v(" "+_vm._s(item.unit)+" ")],1),(item.received && !item.received.create)?_c('div',_vm._l((item.received.products),function(product){return _c('div',{key:product.product_id},[_c('number',{attrs:{"value":product.quantity}}),_vm._v(" "+_vm._s(product.unit)+" ")],1)}),0):_vm._e()]}},{key:"item.unitPrice",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('currency',{attrs:{"value":item.unitPrice || 0}})],1)]}},{key:"item.totalPrice",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('currency',{attrs:{"value":item.totalPrice || 0}})],1)]}},{key:"item.ncm",fn:function(ref){
              var item = ref.item;
return [_c('ncm-field',{attrs:{"text":"","value":item.ncm}})]}},{key:"item.cfop",fn:function(ref){
              var item = ref.item;
return [_c('cfop-field',{attrs:{"text":"","value":item.cfop}})]}},{key:"item.aliquot",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","small":"","block":""},on:{"click":function($event){_vm.editItem = item}}},[_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-check-circle")]),_c('span',[_vm._v("Visualizar")])],1)]}}])})],1)],1)],1),_c('product-edit',{attrs:{"readonly":"","nfeOperation":"purchase","value":_vm.editItem,"fiscal-regime":_vm.taxRegime},on:{"close":function($event){_vm.editItem = null}}}),_c('receive-product',{attrs:{"value":_vm.selectProduct},on:{"input":_vm.selectProductUpdated,"close":function($event){_vm.selectProduct = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }